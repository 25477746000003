<template>
  <span>{{ display }}</span>
</template>
<script>
export default {
  name: "CountdownComponent",
  data() {
    return {
      display: "--d --:--",
      interval: {},
    };
  },
  props: {
    date: {
      type: [String, Object],
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      let duration = this.$moment.duration(
        this.getDateMoment(this.date) - this.$moment()
      );
      this.display = duration.format("Y [lat] M[m] D[d] HH:mm:ss");
    }, 1000);
  },
  methods: {
    getDateMoment(val) {
      if (this.$moment.isMoment(val)) return val.clone();
      if (typeof val === "string") return this.$moment(val);
      return null;
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<template>
  <div>
    <div v-if="isLoading">
      <v-progress-linear
        class="subordinates-list-loader"
        indeterminate
        v-show="isLoading"
        color="loaderColor"
      />
    </div>

    <div
      class="no-subordinate-complaints"
      v-if="subordinateComplaints.length === 0"
    >
      {{ $t("complaints.noSubordinateComplaints") }}
    </div>

    <div class="subordinates-list-container">
      <ComplaintDetailsCard
        v-for="item in subordinateComplaints"
        :cardType="cardType"
        :key="item.cplId"
        :complaint="item"
        :isSelected="selectedComplaintIds.includes(item.cplId)"
        @select="$emit('onSelectItem', item.cplId)"
      />
    </div>
  </div>
</template>

<script>
import { CardTypes } from "@/utils/ComplaintHelper";
import SimilarComplaintsService from "@/services/SimilarComplaintsService.js";

import ComplaintDetailsCard from "./ComplaintDetailsCard";

export default {
  name: "ComplaintSubordinatesList",
  components: {
    ComplaintDetailsCard,
  },
  props: {
    complaintId: String,
    selectedComplaintIds: Array,
  },
  mounted() {
    this.isLoading = true;
    SimilarComplaintsService.GetComplaintsChildren(this.complaintId)
      .then((response) => {
        if (response && response.children) {
          this.subordinateComplaints = [...response.children];
        }
      })
      .finally(() => (this.isLoading = false));
  },
  data() {
    return {
      cardType: CardTypes.subordinate,
      isLoading: false,
      selectedItemIndex: [],
      subordinateComplaints: [],
    };
  },
};
</script>

<style scoped lang="scss">
.subordinates-list-loader {
  width: auto;
}

.no-subordinate-complaints {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
}

.subordinates-list-container {
  padding: 20px;
  max-height: calc(100vh - 150px);
  overflow: auto;
}
</style>

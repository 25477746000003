<template>
  <v-item-group>
    <v-item v-for="item in items" :key="item.id">
      <v-chip
        disabled
        color="chipColor"
        text-color="textColor"
        class="elevation-2"
        :class="{ clickable }"
        @click="onClick(item.id)"
      >
        <TooltipTruncate>
          <slot> {{ item.text }} </slot>
        </TooltipTruncate>
      </v-chip>
    </v-item>
  </v-item-group>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    clickable: { type: Boolean, default: true },
  },
  methods: {
    onClick(id) {
      this.$emit("click", id);
    },
  },
};
</script>

<style scoped>
.chip-container {
  margin: 0;
}
::v-deep .v-chip {
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}
::v-deep .v-chip__content {
  max-width: 100%;
}
::v-deep .clickable .v-chip__content {
  cursor: pointer;
}
::v-deep .v-chip__content > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
</style>

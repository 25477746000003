<template>
  <div class="comment-panel">
    <div>
      <CommentsList :complaintId="complaintId" @scrollDown="scrollDown" />
    </div>
    <div>
      <NewCommentTextField @sendMessage="sendMessage" />
    </div>
  </div>
</template>

<script>
import CommentsList from "./CommentsList";
import NewCommentTextField from "./NewCommentTextField";

export default {
  name: "CommentPanel",
  components: {
    CommentsList,
    NewCommentTextField,
  },
  props: {
    complaintId: { type: String, required: true },
  },
  methods: {
    sendMessage(message) {
      this.$emit("sendMessage", message);
    },
    scrollDown() {
      this.$emit("scrollDown");
    },
  },
};
</script>

<style scoped lang="scss">
.comment-panel {
  display: flex;
  flex-direction: column;
}
</style>

import { required, maxLength } from "vuelidate/lib/validators";
import validationThings from "../validation-things";
import { ComplaintsPropertyRestrictions } from "@/utils/ComplaintHelper";

const isOwnerRequired = (value, vm) => {
  if (!vm.status || !vm.status.ownerRequired) return true;
  if (value && Object.keys(value).length !== 0) return true;
  return false;
};

export default {
  mixins: [validationThings],
  validations: {
    complaint: {
      category: {
        required,
      },
      subCategory: {
        required,
      },
      title: {
        required,
        maxLength: maxLength(ComplaintsPropertyRestrictions.maxTitleLength),
      },
      caller: {
        required,
        maxLength: maxLength(50),
      },
      priority: {
        required: (value) => {
          return !!value;
        },
      },
      source: {
        required: (value) => {
          return !!value;
        },
      },
      owner: isOwnerRequired ? { required } : {},
      description: {
        required,
        maxLength: maxLength(
          ComplaintsPropertyRestrictions.maxDescriptionLength
        ),
      },
    },
  },
};

<template>
  <v-layout wrap v-bind:class="isMobile && 'mobile'">
    <template v-for="item in items">
      <v-flex class="label font-weight-bold" xs6 :key="`${item.id}_owner`">
        {{ item.owner.text }}
      </v-flex>
      <v-flex
        text-right
        xs6
        :key="`${item.id}_insertDate`"
        class="label grey--text"
      >
        {{ item.insertDate | moment("displayDateTime") }}
      </v-flex>
      <v-flex xs12 mb-1 :key="`${item.id}_divider`"><v-divider /></v-flex>
      <v-flex mb-3 xs12 :key="`${item.id}_message`">{{ item.message }}</v-flex>
    </template>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
};
</script>
<style scoped lang="scss">
.label {
  font-size: 12px;
}

.layout.mobile > .flex.mb-3.xs12 {
  word-wrap: break-word;
}
</style>

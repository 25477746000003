import * as pdfMake from "pdfmake";
import i18n from "../plugins/i18n";
import moment from "../plugins/moment";

const lineWidth = 0.3;
const lineColor = "#BDBDBD";
const marginLeft = [20, 0, 0, 0];
let textsCpl = i18n.t("complaints");

export function cplExportToPdf(print, complaint) {
  if (!complaint) return;

  let pdfName = complaint.cId;
  let pdfFonts = require("pdfmake/build/vfs_fonts.js");
  // eslint-disable-next-line no-import-assign
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let docDefinition = {
    pageSize: "A4",
    content: [
      complaint.cId,
      {
        stack: [complaint.title],
        style: "title",
        margin: [0, 10, 0, 30],
      },
      {
        columns: [
          {
            text: [
              {
                text: `${textsCpl.category}: `,
                style: "smaller",
              },
              { text: complaint.category ? complaint.category.text : "" },
            ],
            margin: marginLeft,
          },
          {
            text: [
              {
                text: `${textsCpl.subcategory}: `,
                style: "smaller",
              },
              { text: complaint.subCategory ? complaint.subCategory.text : "" },
            ],
            margin: marginLeft,
          },
        ],
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 0,
            x2: 514,
            y2: 0,
            lineWidth: lineWidth,
            lineColor: lineColor,
          },
        ],
        margin: [0, 15, 0, 15],
      },
      {
        columns: [
          {
            text: [
              {
                text: `${textsCpl.status}: `,
                style: "smaller",
              },
              { text: complaint.status ? complaint.status.text : "" },
            ],
            margin: marginLeft,
          },
          {
            text: [
              {
                text: `${textsCpl.priority}: `,
                style: "smaller",
              },
              { text: complaint.priority ? complaint.priority.text : "" },
            ],
            margin: marginLeft,
          },
        ],
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 0,
            x2: 514,
            y2: 0,
            lineWidth: lineWidth,
            lineColor: lineColor,
          },
        ],
        margin: [0, 15, 0, 15],
      },
      {
        text: [
          {
            text: `${textsCpl.description}: `,
            style: "smaller",
          },
          {
            text: complaint.description
              ? `\n${complaint.description}\n\n`
              : "\n\n",
          },
        ],
        fontSize: 10,
        margin: marginLeft,
      },
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                columns: [
                  {
                    text: [
                      {
                        text: `${textsCpl.owner}: `,
                        style: "smaller",
                      },
                      { text: complaint.owner ? complaint.owner.text : "" },
                    ],
                    margin: [15, 0, 0, 0],
                  },
                  {
                    text: [
                      {
                        text: `${textsCpl.caller}: `,
                        style: "smaller",
                      },
                      { text: complaint.caller ? complaint.caller.text : "" },
                    ],
                    margin: marginLeft,
                  },
                ],
                margin: [0, 10, 0, 10],
              },
            ],
          ],
        },
        layout: {
          //set custom borders size and color
          hLineWidth: () => {
            return lineWidth;
          },
          vLineWidth: () => {
            return lineWidth;
          },
          hLineColor: () => {
            return lineColor;
          },
          vLineColor: () => {
            return lineColor;
          },
        },
      },
      {
        columns: [
          {
            text: [
              {
                text: `${textsCpl.insertDate}: `,
                style: "smaller",
              },
              {
                text: complaint.insertDate
                  ? complaint.insertDate.format("YYYY-MM-DD")
                  : "",
              },
            ],
            margin: marginLeft,
          },
          {
            text: [
              {
                text: `${textsCpl.insertTime}: `,
                style: "smaller",
              },
              {
                text: complaint.insertDate
                  ? complaint.insertDate.format("HH:mm")
                  : "",
              },
            ],
            margin: marginLeft,
          },
        ],
        margin: [0, 20, 0, 10],
      },
      {
        canvas: [
          {
            type: "line",
            x1: 0,
            y1: 0,
            x2: 514,
            y2: 0,
            lineWidth: lineWidth,
            lineColor: lineColor,
          },
        ],
        margin: [0, 15, 0, 15],
      },
      {
        columns: [
          {
            text: [
              {
                text: `${textsCpl.source}: `,
                style: "smaller",
              },
              { text: complaint.source ? complaint.source.text : "" },
            ],
            margin: marginLeft,
          },
        ],
        margin: [0, 0, 0, 20],
      },
    ],
    styles: {
      title: {
        fontSize: 13,
        alignment: "center",
      },
      smaller: {
        fontSize: 9,
        color: "#d3731c",
      },
    },
    defaultStyle: {
      columnGap: 20,
      color: "#323232",
      fontSize: 11,
    },
  };

  complaint.details.forEach((detail) => {
    docDefinition.content.push({
      columns: [
        {
          text: detail.stateName,
        },
        {
          text: moment(detail.insertDate).format("dateTime"),
          alignment: "right",
          fontSize: 10,
        },
      ],
      margin: [0, 10, 0, 10],
    });

    docDefinition.content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: 514,
          y2: 0,
          lineWidth: lineWidth,
          lineColor: lineColor,
        },
      ],
      margin: [0, 0, 0, 10],
    });

    if (detail.params.length === 0) {
      docDefinition.content.push({
        text: i18n.t("complaints.noParams"),
        margin: [20, 0, 0, 20],
      });
    }

    for (var index = 0; index < detail.params.length; index += 2) {
      let col = {
        columns: [
          {
            text: [
              {
                text: `${getParamName(detail.params[index])}:\n`,
                style: "smaller",
              },
              { text: getParamValue(detail.params[index]) },
            ],
            margin: [20, 0, 0, 20],
          },
        ],
      };

      if (detail.params[index + 1]) {
        col.columns.push({
          text: [
            {
              text: `${getParamName(detail.params[index + 1])}:\n`,
              style: "smaller",
            },
            { text: getParamValue(detail.params[index + 1]) },
          ],
          margin: [20, 0, 0, 20],
        });
      }

      docDefinition.content.push(col);
    }
  });

  if (print) {
    pdfMake.createPdf(docDefinition).print({}, window);
  } else {
    pdfMake.createPdf(docDefinition).download(`${pdfName}.pdf`);
  }
}

function getParamName(param) {
  return param.paramName;
}

function getParamValue(param) {
  return param.paramTypeCode === "P_DATETIME"
    ? moment(param.paramValue).format("dateTime")
    : param.paramTypeCode === "P_DATETIME_FROM_TO"
    ? `${i18n.t("common.from")} ${moment(
        JSON.parse(param.paramValue).From
      ).format("dateTime")} ${i18n.t("common.to")} ${moment(
        JSON.parse(param.paramValue).To
      ).format("dateTime")}`
    : param.paramValue;
}

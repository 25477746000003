import axios from "../plugins/http";
import WithUser from "../utils/WithUser";

let apiUrl = "api/complaintnew";

export default {
  GetPriorities() {
    return axios.get(`${apiUrl}/categories/priorities`);
  },
  GetPrioritiesForCategory() {
    return axios.get(`${apiUrl}/categories/priorities`);
  },
  GetSources() {
    return axios.get(`${apiUrl}/sources`);
  },
  GetTags() {
    return axios.get(`${apiUrl}/tags`);
  },
  GetOwners() {
    return axios.get(`${apiUrl}/owners`);
  },
  Create(model) {
    const data = WithUser(model);
    const multipartOpt = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios.post(apiUrl, data, multipartOpt);
  },
  CreateOwn(model) {
    const data = WithUser(model);
    const multipartOpt = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios.post(apiUrl + "/own", data, multipartOpt);
  },
  GetCategories() {
    return axios.get(`${apiUrl}/categories`);
  },
};

<template>
  <div>
    <v-btn icon text @click.stop="isModalShow = true">
      <IconBox color="iconButton" :tooltip="$t('common.add')">
        stem-addSubordinate
      </IconBox>
    </v-btn>

    <v-dialog v-model="isModalShow" width="700">
      <v-card class="content-card">
        <div class="content-header">
          <div class="header-title">
            {{ $t("complaints.addSubordinateComplaint").toUpperCase() }}
          </div>
        </div>

        <div class="content-details">
          <SelectWithAutocomplete
            item-value="id"
            :value="selectedItem"
            :items="items"
            :name="$t('common.autoCompleteAsyncHint')"
            :item-text="(item) => `(${item.cid}) ${item.topic}`"
            @input="onChange"
          />
        </div>

        <div class="content-footer">
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            @click="onCancel"
          >
            {{ $t("common.close") }}
          </v-btn>
          <v-btn
            color="roundButton roundButtonText--text"
            rounded
            :disabled="!selectedItem"
            @click="onConfirm"
          >
            {{ $t("common.save") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SimilarComplaintsService from "@/services/SimilarComplaintsService.js";

import SelectWithAutocomplete from "@/components/Basic/SelectWithAutocomplete";

export default {
  name: "AddSubordinateComplaintModal",
  components: { SelectWithAutocomplete },
  props: {
    complaintId: { type: String },
  },
  data() {
    return {
      isModalShow: false,
      selectedItem: null,
      items: [],
    };
  },
  methods: {
    onCancel() {
      this.isModalShow = false;
    },
    onConfirm() {
      this.isModalShow = false;
      this.$emit("onChange", this.selectedItem);
    },
    onChange(newValue) {
      this.selectedItem = newValue;
    },
  },
  watch: {
    isModalShow(isShown) {
      if (isShown) {
        this.selectedItem = null;

        SimilarComplaintsService.AvailableComplaintsToChain(
          this.complaintId
        ).then((response) => {
          this.items = response.filter((item) => item.id !== this.complaintId);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-card {
  height: 450px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  .content-header {
    flex: 0 0 50px;

    .header-title {
      color: $text-title;
      padding-top: 20px;
    }
  }

  .content-details {
    flex: 1 0 auto;
    padding-top: 30px;
  }

  .content-footer {
    flex: 0 0 50px;
    margin-left: auto;

    :not(:first-child) {
      margin-left: 10px;
    }
  }
}
</style>

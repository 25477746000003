import axios from "axios";

const apiUrl = "api/complaints";

export default {
  GetComplaintsChildren(complaintId) {
    return axios.get(`${apiUrl}/${complaintId}/children`);
  },
  DeleteComplaintFromSubordinates(complaintId, subordinateIds) {
    return axios.delete(`${apiUrl}/${complaintId}/children`, {
      data: subordinateIds,
    });
  },
  AddComplaintToSubordinates(complaintId, subordinateIds) {
    return axios.post(`${apiUrl}/${complaintId}/children`, subordinateIds);
  },
  GetSimilarComplaints(complaintId) {
    return axios.get(`${apiUrl}/${complaintId}/similars`);
  },
  AvailableComplaintsToChain(complaintId) {
    return axios.get(`${apiUrl}/${complaintId}/available`);
  },
};

<template>
  <div>
    <div v-if="isLoading" class="similar-loading">
      <v-progress-linear indeterminate v-show="isLoading" color="loaderColor" />
    </div>

    <div v-else>
      <div v-if="similarComplaints.length > 0" class="similar-complaints-list">
        <ComplaintDetailsCard
          v-for="item in similarComplaints"
          :key="item.id"
          :complaint="item"
          :cardType="cardType"
          :canAdd="canAddSubordinates"
          @addToSubordinate="addToSubordinate"
          @removeFromSubordinate="deleteFromSubordinate"
          @removeFromOtherSubordinate="deleteFromSubordinate"
        />
      </div>

      <div v-else class="no-similar-complaints">
        {{ $t("complaints.noSimilarComplaints") }}
      </div>
    </div>
  </div>
</template>

<script>
import { CardTypes } from "@/utils/ComplaintHelper";
import SimilarComplaintsService from "@/services/SimilarComplaintsService.js";

import ComplaintDetailsCard from "@/components/Complaints/ComplaintDetailsCard";

export default {
  name: "ComplaintSimilarsList",
  components: {
    ComplaintDetailsCard,
  },
  props: {
    complaintId: String,
    similarComplaints: { type: Array, default: () => [] },
    canAddSubordinates: { type: Boolean, default: true },
  },
  data() {
    return {
      isLoading: false,
      cardType: CardTypes.similar,
    };
  },
  mounted() {
    this.$emit("getSimilarComplaints");
  },
  methods: {
    addToSubordinate(similarComplaintId) {
      this.isLoading = true;

      SimilarComplaintsService.AddComplaintToSubordinates(this.complaintId, [
        similarComplaintId,
      ])
        .then(() => {
          this.$emit("updateComplaint");
          this.$emit("getSimilarComplaints");
        })
        .finally(() => (this.isLoading = false));
    },
    deleteFromSubordinate(similarComplaintId, parentComplaintId) {
      this.isLoading = true;
      const parentId = parentComplaintId || this.complaintId;

      SimilarComplaintsService.DeleteComplaintFromSubordinates(parentId, [
        similarComplaintId,
      ])
        .then(() => {
          this.$emit("updateComplaint");
          this.$emit("getSimilarComplaints");
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.similar-complaints-list {
  padding: 15px;
}

.no-similar-complaints {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
}
</style>

<template>
  <div>
    <v-progress-linear
      class="loader"
      indeterminate
      v-show="isLoading"
      color="loaderColor"
    />
    <DraftGuard :work-in-progress="editMode">
      <div
        id="complaint-details-wrapper"
        v-if="complaint != null && !isLoading"
        :class="`${allowToSplitView ? viewMode : 'single'}`"
        v-resize="onWidthChange"
      >
        <div class="complaint-details">
          <div class="content-section complaint-title">
            <div class="text">
              {{ complaint.title }}
            </div>
            <div class="complaint-actions">
              <div v-if="selectedSubcategoryId" class="attachment-action">
                <AttachmentModal
                  v-if="$can('CPL_READ')"
                  v-slot="{ on, count }"
                  :complaintId="complaint.id"
                  :readonly="$can('CPL_WRITE') && !editMode"
                  :attachmentsCount="complaint.attachments.length"
                  :subcategoryId="selectedSubcategoryId"
                  @onChange="onAttachmentsChange"
                >
                  <AttachmentBadge v-bind:event-handler="on" :count="count" />
                </AttachmentModal>
              </div>

              <div v-else class="attachment-action">
                <IconBox
                  color="disabled"
                  :tooltip="$t('complaints.selectFirstSubcategory')"
                >
                  stem-attachment
                </IconBox>
              </div>

              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs" class="header-actions-button">
                    <IconBox
                      color="iconButton"
                      :tooltip="$t('common.actions')"
                      class="header-actions"
                    >
                      stem-headerActions
                    </IconBox>
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="exportToPdf(false)">
                    <v-list-item-avatar>
                      <IconBox color="iconButton">stem-export</IconBox>
                    </v-list-item-avatar>
                    <v-list-item-content xs-align-right>
                      <v-list-item-title>
                        {{ $t("complaints.exportToPdf") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="exportToPdf(true)">
                    <v-list-item-avatar>
                      <IconBox color="iconButton">stem-print</IconBox>
                    </v-list-item-avatar>
                    <v-list-item-content xs-align-right>
                      <v-list-item-title>
                        {{ $t("common.print") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="complaint.isCloneable"
                    @click="cloneComplaint"
                  >
                    <v-list-item-avatar>
                      <IconBox color="iconButton">stem-copy</IconBox>
                    </v-list-item-avatar>
                    <v-list-item-content xs-align-right>
                      <v-list-item-title>
                        {{ $t("complaints.clone") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <!-- <v-list-item
                    v-if="
                      !isComplaintClosedOrCanceled &&
                        complaint.owner &&
                        isLoggedUserAdmin
                    "
                    @click="onSendRealizationReminder"
                  >
                    <v-list-item-avatar>
                      <IconBox color="iconButton">
                        stem-sendRealizationReminder
                      </IconBox>
                    </v-list-item-avatar>
                    <v-list-item-content xs-align-right>
                      <v-list-item-title>
                        {{ $t("complaints.sendRealizationReminder") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->

                  <!-- <v-list-item
                    v-if="
                      !isComplaintClosedOrCanceled &&
                        !complaint.owner &&
                        isLoggedUserAdmin
                    "
                  >
                    <v-list-item-avatar>
                      <IconBox color="iconButton">
                        stem-sendRealizationReminder
                      </IconBox>
                    </v-list-item-avatar>
                    <v-tooltip v-if="!complaint.owner" bottom>
                      <template v-slot:activator="{ on }">
                        <v-list-item-content v-on="on" xs-align-right>
                          {{ $t("complaints.sendRealizationReminder") }}
                        </v-list-item-content>
                      </template>
                      <span>
                        {{ $t("complaints.canNotSendRealizationReminder") }}
                      </span>
                    </v-tooltip>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </div>
          </div>

          <div class="content-section complaint-status">
            <div class="labels">
              <div>
                {{ $t("complaints.complaintStatus").toUpperCase() }}
              </div>
              <div>
                {{ $t("complaints.jiraModuleIntegration").toUpperCase() }}
              </div>
            </div>

            <div class="details">
              <div v-if="editMode" class="section">
                <CategoryField
                  selectField
                  return-object
                  :edit="editMode && availableStates.length > 1"
                  :label="textsCpl.status"
                  :options="availableStates"
                  :value="complaint.status"
                  @input="onStatusChange"
                />
              </div>
              <div v-else class="section">
                <div class="value">
                  {{ complaint.status.text }}
                </div>
              </div>

              <div
                v-if="
                  complaint.externalModulesStates.jiraModuleIntegrationActive
                "
                class="section"
              >
                <div class="value">
                  {{ complaint.externalModulesStates.key }}
                </div>
                <div class="integration-value">
                  <a
                    :href="complaint.externalModulesStates.self"
                    target="_blank"
                  >
                    {{ complaint.externalModulesStates.id }}
                  </a>
                </div>
              </div>
              <div v-else class="section">
                <v-btn
                  normal
                  rounded
                  color="roundButton roundButtonText--text"
                  :loading="jiraIntegrationLoading"
                  @click="jiraIntegrationButtonClick"
                  :disabled="complaint.status.id !== 1 || !hasOwner"
                >
                  {{ $t("complaints.sendToJira") }}
                </v-btn>
              </div>
            </div>
          </div>

          <div class="content-section">
            <div class="triple">
              <CategoryField
                :label="textsCpl.category"
                :value="complaint.category"
                itemText="text"
                @input="(value) => onChangeValue('category', value)"
              >
                {{ complaint.category.text }}
              </CategoryField>
            </div>

            <div class="triple">
              <CategoryField
                selectField
                :edit="canEditSubcategory"
                :options="getSubcategory"
                :label="textsCpl.subcategory"
                required
                :value="complaint.subCategory"
                return-object
                itemText="text"
                :error-messages="errors.subCategory"
                @input="(value) => onChangeValue('subcategory', value)"
              />
            </div>

            <div class="triple">
              <CategoryField
                :edit="editMode"
                selectField
                :options="priorityOptions"
                itemText="text"
                :label="textsCpl.priority"
                :value="complaint.priority"
                return-object
                :error-messages="errors.priority"
                @input="(value) => onChangeValue('priority', value)"
              >
                <PriorityArrow
                  :id="complaint.priority.id"
                  :height="15"
                  :setBackground="true"
                />
                {{ complaint.priority.text }}
              </CategoryField>
            </div>
          </div>

          <div class="content-section">
            <CategoryField
              :edit="editMode"
              textAreaField
              :label="textsCpl.description"
              :value="complaint.description"
              @input="(value) => onChangeValue('description', value)"
            >
              {{ complaint.description }}
            </CategoryField>
          </div>

          <div class="content-section">
            <div class="double">
              <CategoryField
                :label="textsCpl.caller"
                value="complaint.caller"
                itemText="text"
                :error-messages="errors.caller"
                @input="(value) => onChangeValue('caller', value)"
              >
                <TooltipTruncate>
                  {{ complaint.caller.text }}
                </TooltipTruncate>
              </CategoryField>
            </div>

            <div class="double">
              <CategoryField
                :disabled="hasOwner"
                autocompleteField
                :edit="editMode"
                :options="owners"
                :label="textsCpl.owner"
                required
                :item-text="
                  (item) => `${item.surname} ${item.name} (${item.login})`
                "
                :value="complaint.owner"
                :error-messages="displayErrors.owner"
                @input="(value) => onChangeValue('owner', value)"
                item-value="id"
                return-object
              >
                <TooltipTruncate>
                  {{ ownerText }}
                </TooltipTruncate>
                <OwnerListItem slot="item" slot-scope="{ item }" :item="item" />
              </CategoryField>
            </div>

            <div v-if="false" class="complaint-realisation-date">
              <span class="countdown-label">
                {{ textsCommon.countdown }}
              </span>
              <CountdownComponent :date="complaint.realisationDate" />
            </div>
          </div>

          <div class="content-section">
            <div class="double">
              <CategoryField
                datePickerField
                :label="textsCpl.insertDate"
                :value="complaint.insertDate"
                @input="(value) => onChangeValue('insertDate', value)"
              >
                {{ complaint.insertDate | moment("displayDate") }}
              </CategoryField>
            </div>

            <div class="double">
              <CategoryField
                timePickerField
                :label="textsCpl.insertTime"
                :value="complaint.insertDate"
                @input="(value) => onChangeValue('insertDate', value)"
              >
                {{ complaint.insertDate | moment("time") }}
              </CategoryField>
            </div>

            <!-- <div class="quadruple">
              <CategoryField
                v-if="showRealisationDate"
                :edit="false"
                datePickerField
                :label="textsCpl.realisationDate"
                :value="complaint.realisationDate"
              >
                {{ complaint.realisationDate | moment("displayDate") }}
              </CategoryField>
            </div> -->

            <!-- <div class="quadruple">
              <CategoryField
                v-if="showRealisationDate"
                timePickerField
                :edit="false"
                :label="textsCpl.realisationTime"
                :value="complaint.realisationDate"
              >
                {{ complaint.realisationDate | moment("time") }}
              </CategoryField>
            </div> -->
          </div>

          <div class="content-section">
            <CategoryField
              :edit="editMode"
              selectTagsField
              :options="getTags"
              class="my-2 xs11"
              :label="textsCpl.tags"
              :value="complaint.tags"
              @input="(value) => onChangeValue('tags', value)"
            >
              <div v-if="complaint.tags.length === 0" class="empty-tags-info">
                Brak tagów
              </div>
              <TagsList v-else :items="complaint.tags" :clickable="false" />
            </CategoryField>
          </div>

          <div class="content-section">
            <div class="quadruple">
              <CategoryField
                :edit="editMode"
                selectField
                :options="getSources"
                return-object
                :label="textsCpl.source"
                :value="complaint.source"
                :error-messages="errors.source"
                @input="(value) => onChangeValue('source', value)"
              >
                {{ complaint.source.text }}
              </CategoryField>
            </div>
          </div>
        </div>

        <div class="complaint-dynamic-details">
          <v-tabs
            slider-color="fields"
            background-color="transparent"
            :value="selectedTabIndex"
            @change="onTabChange"
          >
            <v-tab>{{ $t("complaints.details") }}</v-tab>
            <v-tab-item>
              <ComplaintDetailsDynamic
                ref="dynamicDetails"
                :value="complaint.details"
                :edit="editMode"
                :subCategoryId="+complaint.subCategory.id"
                :baseStateId="baseStateId"
                :stateId="complaint.status.id"
                :states="states"
                :detailsError="detailsError"
                @input="(value) => onChangeValue('details', value)"
              />
            </v-tab-item>

            <v-tab :disabled="complaint.histories.length === 0">
              {{ textsCpl.history }}
            </v-tab>
            <v-tab-item>
              <v-card class="right-side-card">
                <v-layout py-3 justify-space-around>
                  <v-flex xs11>
                    <HistoryList :items="complaint.histories"></HistoryList>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item>

            <v-tab>{{ textsCpl.comments }}</v-tab>
            <v-tab-item>
              <v-card class="right-side-card comments">
                <v-layout justify-space-around>
                  <v-flex xs11>
                    <CommentPanel
                      :complaintId="complaint.id"
                      @sendMessage="saveComment"
                      @scrollDown="scrollDownCommentList"
                    />
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item>

            <v-tab>{{ textsCpl.similarComplaints }}</v-tab>
            <v-tab-item>
              <ComplaintSimilarsList
                :complaintId="complaint.id"
                :similarComplaints="similarComplaints"
                :canAddSubordinates="!complaint.isChild"
                @updateComplaint="updateComplaint"
                @getSimilarComplaints="$emit('getSimilarComplaints')"
              />
            </v-tab-item>
          </v-tabs>
        </div>
      </div>

      <SendRealizationReminderModal ref="sendRealizationModal" />
    </DraftGuard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import * as CplStates from "@/models/cplStates.js";

import { cplExportToPdf } from "@/utils/CplExportToPdf";
import ComplaintsService from "../../services/ComplaintsService.js";
import ComplaintNewService from "../../services/ComplaintNewService.js";
import ComplaintValidators from "@/mixins/validators/complaint-validators";

import CountdownComponent from "../Shared/CountdownComponent";
import PriorityArrow from "../Shared/PriorityArrow";
import CategoryField from "../Fields/CategoryField";
import HistoryList from "../Shared/HistoryList";
import CommentPanel from "@/components/Shared/CommentPanel";
import ComplaintDetailsDynamic from "../Complaints/ComplaintDetailsDynamic";
import AttachmentBadge from "../Shared/Attachment/AttachmentBadge";
import AttachmentModal from "../Shared/Attachment/AttachmentModal";
import OwnerListItem from "@/components/ListItems/OwnerListItem";
import TagsList from "@/components/Shared/TagsList";
import DraftGuard from "../DraftGuard";
import ComplaintSimilarsList from "@/components/Complaints/ComplaintSimilarsList";
import SendRealizationReminderModal from "@/components/Complaints/SendRealizationReminderModal";

export default {
  components: {
    DraftGuard,
    AttachmentBadge,
    AttachmentModal,
    CountdownComponent,
    PriorityArrow,
    CategoryField,
    HistoryList,
    CommentPanel,
    ComplaintDetailsDynamic,
    OwnerListItem,
    TagsList,
    ComplaintSimilarsList,
    SendRealizationReminderModal,
  },
  mixins: [ComplaintValidators],
  props: {
    complaint: Object,
    allowToSplitView: Boolean,
    similarComplaints: Array,
    selectedTabIndex: Number,
    editMode: Boolean,
    baseStateId: Number,
    states: Array,
    availableStates: Array,
    detailsError: Object,
  },
  data() {
    return {
      cplStates: CplStates,
      owners: [],
      complaintCopy: {},
      textsCpl: this.$t("complaints"),
      textsCommon: this.$t("common"),
      hasOwner: false,
      oldChainedComplaints: [],
      isLoading: false,
      viewMode: "single",
      jiraIntegrationLoading: false,
      displayErrors: {},
      priorities: [],
    };
  },
  mounted() {
    window.onresize = () => {
      this.onWidthChange();
    };

    this.hasOwner = this.complaint.owner && this.complaint.owner.id !== null;

    if (this.complaint.owner === null) {
      this.displayErrors = {
        ...this.displayErrors,
        owner: this.$t("rules.required"),
      };
    } else {
      this.displayErrors = { ...this.displayErrors };
    }
    this.getOwners(this.complaint.category.id).then(
      (res) => (this.owners = res)
    );
    this.getPriorities();
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
    canEditSubcategory() {
      return this.editMode && this.complaint && !this.complaint.subCategory.id;
    },
    isLoggedUserAdmin() {
      return this.$store.state.settings.isAdminRole;
    },
    selectedSubcategoryId() {
      return this.complaint.subCategory.id || null;
    },
    showRealisationDate() {
      return (
        this.complaint.status.id !== this.cplStates.REALIZATION &&
        this.baseStateId !== this.cplStates.REALIZATION
      );
    },
    // Tymczasowe rozwiązanie, SLA jak na razie nie uwzględnia funkcji "zamrażania"
    isSLAExpiring() {
      return this.complaint.status
        ? this.complaint.status.id < this.cplStates.COMPLETED
        : false;
    },
    ownerText() {
      return this.complaint.owner ? this.complaint.owner.text : "";
    },
    isComplaintClosedOrCanceled() {
      return (
        this.complaint.status.id === this.cplStates.CLOSED ||
        this.complaint.status.id === this.cplStates.CANCELED
      );
    },
    priorityOptions() {
      if (!this.complaint.category || this.priorities.length === 0) return [];

      const availableOptions = this.priorities.find(
        (item) => item.categoryId === this.complaint.category.id
      );

      return availableOptions ? availableOptions.priorities : [];
    },
  },
  watch: {
    "complaint.owner"(owner) {
      if (this.complaint.owner === null || owner === null) {
        this.displayErrors = {
          ...this.displayErrors,
          owner: this.$t("rules.required"),
        };
      } else {
        this.displayErrors = {
          ...this.displayErrors,
          owner: null,
        };
      }
    },
    "complaint.subCategory"(subCategory) {
      if (
        subCategory === null ||
        subCategory.id === undefined ||
        subCategory.id === 0
      ) {
        return;
      }

      if (this.canEditSubcategory) {
        this.getSubCategoryOwner(subCategory.id).then((owner) => {
          this.$emit("owner", owner ? owner : null);
        });
      }
    },
  },
  methods: {
    getPriorities() {
      ComplaintNewService.GetPrioritiesForCategory().then((response) => {
        this.priorities = response;
      });
    },
    getOwners(categoryId) {
      return ComplaintsService.GetCategoryOwners(categoryId);
    },
    getSources() {
      return ComplaintNewService.GetSources();
    },
    getTags() {
      return ComplaintNewService.GetTags();
    },
    getSubcategory() {
      return ComplaintNewService.GetSubcategories(this.complaint.category.id);
    },
    getSubCategoryOwner(subCategoryId) {
      return ComplaintsService.GetSubCategoryOwner(subCategoryId);
    },
    saveComment(commentMessage) {
      let complaintComment = {
        cplId: this.complaint.id,
        cplTopic: this.complaint.title,
        message: commentMessage,
      };
      ComplaintsService.CreateComment(complaintComment);
    },
    exportToPdf(print) {
      let cpl = { ...this.complaint };
      cpl.details = cpl.details.map((x) => {
        return {
          ...x,
          stateName: (this.states.find((s) => s.stateId === x.stateId) || {})[
            "name"
          ].toUpperCase(),
        };
      });

      cplExportToPdf(print, cpl);
    },
    getAvailableComplaintsToChain(val) {
      if (val === undefined) return new Promise(() => []);
      return ComplaintsService.GetAvailableComplaintsToChain(val);
    },
    cloneComplaint() {
      this.$router.push({
        name: "newComplaint",
        params: { complaintToClone: this.complaint },
      });
    },
    onWidthChange() {
      const container = document.getElementById("complaint-details-wrapper");
      const containerWidth = container ? container.offsetWidth : undefined;
      this.viewMode =
        containerWidth && containerWidth > 1000 ? "split" : "single";
    },
    updateComplaint() {
      this.$emit("updateComplaint");
    },
    onTabChange(tabIndex) {
      this.$emit("onTabChange", tabIndex);
    },
    onAttachmentsChange(newValue) {
      this.$emit("onAttachmentsChange", newValue);
    },
    onSendRealizationReminder() {
      this.$refs.sendRealizationModal.openModal(this.complaint);
    },
    onStatusChange(status) {
      this.$emit("onStatusChange", status);
    },
    jiraIntegrationButtonClick() {
      this.jiraIntegrationLoading = true;

      ComplaintsService.sendComplaintToJira(this.complaint.id)
        .then(() => {
          this.updateComplaint();
        })
        .finally(() => (this.jiraIntegrationLoading = false));
    },
    onChangeValue(propertyName, value) {
      this.$emit("onChange", { [propertyName]: value });
    },
    scrollDownCommentList() {
      const elementToScroll =
        this.viewMode === "single"
          ? "#complaint-details-wrapper"
          : ".complaint-dynamic-details";
      const commentPanelElement = document.querySelector(elementToScroll);
      commentPanelElement.scrollTop = commentPanelElement.scrollHeight;
    },
  },
};
</script>
<style scoped lang="scss">
.loader {
  margin: -12px -12px 0 -12px;
  width: auto;
}

#complaint-details-wrapper {
  .comment-panel {
    margin-bottom: 50px;
  }
}

#complaint-details-wrapper {
  display: flex;
  padding-top: 40px;
  max-height: calc(100vh - 160px);

  .complaint-details {
    padding: 0 20px;
  }

  .complaint-dynamic-details {
    padding: 0 20px;
  }

  &.single {
    flex-direction: column;
    overflow: auto;
  }

  &.split {
    flex-direction: row;

    .complaint-details {
      flex: 1 1 50%;
      overflow: auto;
    }

    .complaint-dynamic-details {
      flex: 1 1 50%;
      overflow: auto;
    }
  }
}

.content-section {
  display: flex;
  flex-direction: row;
  padding: 20px;
  word-break: break-word;

  &:nth-child(odd) {
    background-color: $content-section-background;
  }

  .double {
    width: 50%;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .triple {
    width: 33%;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .quadruple {
    width: 25%;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  &.complaint-title {
    align-items: center;
    display: flex;
    flex-direction: row;

    .text {
      padding-right: 10px;
      font-style: italic;
    }

    .complaint-actions {
      flex: 0 0 70px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: auto;

      .attachment-action {
        margin-right: 10px;
      }
    }
  }

  &.complaint-status {
    display: flex;
    flex-direction: column;

    .labels {
      display: flex;
      flex-direction: row;
      color: $text-color;
      padding-bottom: 10px;
      border-bottom: 1px solid $border-text-color;

      div {
        width: 50%;
        text-align: center;
      }
    }

    .details {
      display: flex;
      flex-direction: row;
      padding-top: 10px;

      .section {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $label;

        .value {
          border: 1px solid $label;
          padding: 5px 10px;
          min-width: 100px;
          text-align: center;
          margin-bottom: 10px;
        }

        .integration-value {
          text-align: center;
        }
      }
    }
  }

  .complaint-realisation-date {
    display: flex;
    flex-direction: column;

    .countdown-label {
      color: $text-color;
      font-size: 13px;
    }
  }

  .empty-tags-info {
    color: #7e7d7f;
  }
}
</style>

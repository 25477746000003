<template>
  <div>
    <div v-if="isLoading">
      <v-progress-linear
        class="container-loader"
        indeterminate
        v-show="isLoading"
        color="loaderColor"
      />
    </div>

    <div
      v-if="complaint && !complaint.isParent"
      class="complaint"
      v-resize="onWidthChange"
    >
      <v-tabs hide-slider background-color="#fafafa">
        <v-tab class="header-tab-item">
          <v-btn @click="goBack" icon class="go-back-arrow">
            <IconBox
              :tooltip="$t('common.back')"
              :styles="{ 'font-size': '20px' }"
              color="iconButton"
            >
              stem-arrowBack
            </IconBox>
          </v-btn>
          <div class="header-tab-item-selected">
            {{ complaint.cId }}
          </div>
          <IconBox v-if="complaint.isChild" color="iconButton">
            stem-subordinateComplaint
          </IconBox>
        </v-tab>
        <v-tab-item>
          <ComplaintDetails
            :editMode="isEditMode"
            :complaint="complaintEditorState"
            :baseStateId="baseStateId"
            :allowToSplitView="viewMode === 'single'"
            :similarComplaints="similarComplaints"
            :selectedTabIndex="selectedTabIndex"
            :states="states"
            :availableStates="availableStates"
            @onChange="onValueChange"
            @onStatusChange="onStatusChange"
            @onAttachmentsChange="onAttachmentsChange"
            @updateComplaint="() => fetchComplaint(complaintId)"
            @getSimilarComplaints="fetchSimilarComplaints"
            @onTabChange="onTabChange"
            @click="setEditMode"
            :detailsError="detailsError"
          />
        </v-tab-item>

        <div class="header-buttons">
          <div v-if="isEditActionButtonsVisible" class="edit-action-buttons">
            <v-menu bottom left offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="roundButton"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="isLoading || !isAnythingEdited"
                >
                  <IconBox color="roundButtonText"> stem-confirm </IconBox>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="() => onSave('complaintDetails')">
                  <v-list-item-title>
                    {{ $t("common.save") }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="() => onSaveAndClose('complaintDetails')">
                  <v-list-item-title>
                    {{ $t("common.saveAndExit") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn color="roundButton" @click="onCancel" :disabled="isLoading">
              <IconBox
                class="cancel-button"
                color="roundButtonText"
                :tooltip="$t('common.cancel')"
              >
                fa-times
              </IconBox>
            </v-btn>
          </div>

          <div v-if="isEditButtonVisible">
            <v-btn
              v-if="userHasPermissionToModifyComplaint"
              class="button"
              icon
              text
              @click="setEditMode"
            >
              <IconBox color="iconButton" :tooltip="$t('common.edit')">
                stem-edit
              </IconBox>
            </v-btn>
            <div v-else>
              <v-btn icon text :ripple="false">
                <IconBox
                  color="disabled"
                  :tooltip="$t('restrictions.noPermissionToModify')"
                >
                  stem-edit
                </IconBox>
              </v-btn>
            </div>
          </div>

          <div class="long-button-icon" v-if="!complaint.isChild">
            <AddSubordinateComplaintModal
              :complaintId="complaintId"
              @onChange="onAddSubordinateComplaint"
            />
          </div>
        </div>
      </v-tabs>
    </div>

    <div
      v-if="complaint && complaint.isParent"
      class="parent-complaint"
      v-resize="onWidthChange"
    >
      <div v-if="viewMode === 'single'" class="single">
        <v-tabs
          hide-slider
          background-color="#fafafa"
          :value="selectedViewTab"
          @change="onSelectViewTabChange"
        >
          <v-tab class="header-tab-item">
            <v-btn @click="goBack" icon class="go-back-arrow">
              <IconBox
                :tooltip="$t('common.back')"
                :styles="{ 'font-size': '20px' }"
                color="iconButton"
              >
                stem-arrowBack
              </IconBox>
            </v-btn>
            <div
              :class="selectedViewTab === 0 ? 'header-tab-item-selected' : ''"
            >
              {{ complaint.cId }}
            </div>
            <IconBox color="iconButton"> stem-parentComplaint </IconBox>
          </v-tab>
          <v-tab-item>
            <ComplaintDetails
              :editMode="isEditMode"
              :complaint="complaintEditorState"
              :baseStateId="baseStateId"
              :allowToSplitView="viewMode === 'single'"
              :similarComplaints="similarComplaints"
              :selectedTabIndex="selectedTabIndex"
              :states="states"
              :availableStates="availableStates"
              @onChange="onValueChange"
              @onStatusChange="onStatusChange"
              @onAttachmentsChange="onAttachmentsChange"
              @updateComplaint="updateParentComplaint"
              @getSimilarComplaints="fetchSimilarComplaints"
              @onTabChange="onTabChange"
            />
          </v-tab-item>

          <v-tab class="header-tab-item">
            <div
              :class="selectedViewTab === 1 ? 'header-tab-item-selected' : ''"
            >
              {{ $t("complaints.subordinateComplaints") }}
            </div>
            <IconBox color="iconButton"> stem-subordinateComplaint </IconBox>
          </v-tab>
          <v-tab-item>
            <ComplaintSubordinatesList
              :key="componentKey"
              :complaintId="complaintId"
              :selectedComplaintIds="selectedSubordinateComplaintIds"
              @onSelectItem="onSubordinateComplaintSelect"
            />
          </v-tab-item>

          <div class="header-buttons">
            <div class="long-button-icon">
              <AddSubordinateComplaintModal
                v-if="isSubordinateButtonsVisible"
                :complaintId="complaintId"
                @onChange="onAddSubordinateComplaint"
              />
            </div>
            <div class="long-button-icon">
              <v-btn
                v-if="isSubordinateButtonsVisible"
                class="button"
                icon
                text
                @click.stop="onDeleteSubordinateComplaint"
              >
                <IconBox color="iconButton" :tooltip="$t('common.delete')">
                  stem-deleteSubordinate
                </IconBox>
              </v-btn>
            </div>

            <div v-if="isEditActionButtonsVisible" class="edit-action-buttons">
              <v-menu bottom left offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="roundButton"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="isLoading || !isAnythingEdited"
                  >
                    <IconBox color="roundButtonText"> stem-confirm </IconBox>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="() => onSave('singleComplaintDetails')">
                    <v-list-item-title>
                      {{ $t("common.save") }}
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="() => onSaveAndClose('singleComplaintDetails')"
                  >
                    <v-list-item-title>
                      {{ $t("common.saveAndExit") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn color="roundButton" @click="onCancel">
                <IconBox
                  class="cancel-button"
                  color="roundButtonText"
                  :tooltip="$t('common.cancel')"
                  :disabled="isLoading"
                >
                  fa-times
                </IconBox>
              </v-btn>
            </div>

            <div v-if="isEditButtonVisible">
              <v-btn
                v-if="userHasPermissionToModifyComplaint"
                class="button"
                icon
                text
                @click="setEditMode"
              >
                <IconBox color="iconButton" :tooltip="$t('common.edit')">
                  stem-edit
                </IconBox>
              </v-btn>
              <div v-else>
                <v-btn icon text :ripple="false">
                  <IconBox
                    color="disabled"
                    :tooltip="$t('restrictions.noPermissionToModify')"
                  >
                    stem-edit
                  </IconBox>
                </v-btn>
              </div>
            </div>
            <v-menu offset-y open-on-hover v-if="isSplitViewAvailable">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon text class="button" v-bind="attrs" v-on="on">
                  <IconBox color="iconButton">stem-viewMode</IconBox>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeViewMode('single')">
                  <v-list-item-title>
                    <IconBox color="iconButton">stem-singleView</IconBox>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeViewMode('split')">
                  <v-list-item-title>
                    <IconBox color="iconButton"> stem-splitView </IconBox>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-tabs>
      </div>

      <div v-if="viewMode === 'split'" class="split">
        <div class="left-side">
          <v-tabs hide-slider background-color="#fafafa">
            <v-tab class="header-tab-item">
              <v-btn @click="goBack" icon class="go-back-arrow">
                <IconBox
                  :tooltip="$t('common.back')"
                  :styles="{ 'font-size': '20px' }"
                  color="iconButton"
                >
                  stem-arrowBack
                </IconBox>
              </v-btn>
              <div class="header-tab-item-selected">
                {{ complaint.cId }}
              </div>
              <IconBox color="iconButton"> stem-parentComplaint </IconBox>
            </v-tab>
            <v-tab-item>
              <ComplaintDetails
                :editMode="isEditMode"
                :complaint="complaintEditorState"
                :baseStateId="baseStateId"
                :similarComplaints="similarComplaints"
                :selectedTabIndex="selectedTabIndex"
                :states="states"
                :availableStates="availableStates"
                :detailsError="detailsError"
                @onChange="onValueChange"
                @onStatusChange="onStatusChange"
                @onAttachmentsChange="onAttachmentsChange"
                @updateComplaint="updateParentComplaint"
                @getSimilarComplaints="fetchSimilarComplaints"
                @onTabChange="onTabChange"
              />
            </v-tab-item>

            <div class="header-buttons">
              <div
                v-if="isEditActionButtonsVisible"
                class="edit-action-buttons"
              >
                <v-menu bottom left offset-y open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="roundButton"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="isLoading || !isAnythingEdited"
                    >
                      <IconBox color="roundButtonText"> stem-confirm </IconBox>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="() => onSave('splitComplaintDetails')">
                      <v-list-item-title>
                        {{ $t("common.save") }}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      @click="() => onSaveAndClose('splitComplaintDetails')"
                    >
                      <v-list-item-title>
                        {{ $t("common.saveAndExit") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn color="roundButton" @click="onCancel">
                  <IconBox
                    class="cancel-button"
                    color="roundButtonText"
                    :tooltip="$t('common.cancel')"
                    :disabled="isLoading"
                  >
                    fa-times
                  </IconBox>
                </v-btn>
              </div>

              <div v-if="isEditButtonVisible">
                <v-btn
                  v-if="userHasPermissionToModifyComplaint"
                  class="button"
                  icon
                  text
                  @click="setEditMode"
                >
                  <IconBox color="iconButton" :tooltip="$t('common.edit')">
                    stem-edit
                  </IconBox>
                </v-btn>
                <div v-else>
                  <v-btn icon text :ripple="false">
                    <IconBox
                      color="disabled"
                      :tooltip="$t('restrictions.noPermissionToModify')"
                    >
                      stem-edit
                    </IconBox>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-tabs>
        </div>
        <div class="right-side">
          <v-tabs hide-slider background-color="#fafafa">
            <v-tab class="header-tab-item">
              <div class="header-tab-item-selected">
                {{ $t("complaints.subordinateComplaints") }}
              </div>
              <IconBox color="iconButton"> stem-subordinateComplaint </IconBox>
            </v-tab>
            <v-tab-item>
              <ComplaintSubordinatesList
                :key="componentKey"
                :complaintId="complaintId"
                :selectedComplaintIds="selectedSubordinateComplaintIds"
                @onSelectItem="onSubordinateComplaintSelect"
              />
            </v-tab-item>

            <div class="header-buttons">
              <div class="long-button-icon">
                <AddSubordinateComplaintModal
                  v-if="isSubordinateButtonsVisible"
                  :complaintId="complaintId"
                  @onChange="onAddSubordinateComplaint"
                />
              </div>
              <div class="long-button-icon">
                <v-btn
                  v-if="isSubordinateButtonsVisible"
                  icon
                  text
                  @click.stop="onDeleteSubordinateComplaint"
                >
                  <IconBox color="iconButton" :tooltip="$t('common.delete')">
                    stem-deleteSubordinate
                  </IconBox>
                </v-btn>
              </div>
              <v-menu offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isSplitViewAvailable"
                    icon
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    <IconBox color="iconButton">stem-viewMode</IconBox>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="changeViewMode('single')">
                    <v-list-item-title>
                      <IconBox color="iconButton">stem-singleView</IconBox>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="changeViewMode('split')">
                    <v-list-item-title>
                      <IconBox color="iconButton"> stem-splitView </IconBox>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

import * as CplStates from "@/models/cplStates.js";
import { EmitSuccess, EmitError } from "@/eventBus.js";
import { validateComplaintBeforeSave } from "@/mixins/validators";

import ComplaintValidators from "@/mixins/validators/complaint-validators";
import ComplaintsService from "@/services/ComplaintsService.js";
import SimilarComplaintsService from "@/services/SimilarComplaintsService.js";
import DictionaryService from "@/services/DictionaryService.js";

import ComplaintDetails from "@/components/Complaints/ComplaintDetails";
import ComplaintSubordinatesList from "@/components/Complaints/ComplaintSubordinatesList";
import AddSubordinateComplaintModal from "@/components/Complaints/AddSubordinateComplaintModal";

export default {
  name: "ComplaintDetailsProvider",
  components: {
    ComplaintDetails,
    ComplaintSubordinatesList,
    AddSubordinateComplaintModal,
  },
  mixins: [ComplaintValidators],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setComplaintId(to.params.id);
      vm.setPreviousDestinaion(from.name);

      if (to.params.selectedCustomer) {
        vm.setPreviousCustomer(to.params.selectedCustomer);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next((vm) => vm.setComplaintId(to.params.id));
  },
  data() {
    return {
      componentKey: 0,
      isEditMode: false,
      complaintId: null,
      windowWidth: window.innerWidth,
      viewMode: "single",
      isLoading: false,
      complaint: null,
      complaintEditorState: null,
      baseStateId: 0,
      baseDetails: [],
      similarComplaints: [],
      selectedViewTab: 0,
      selectedSubordinateComplaintIds: [],
      selectedTabIndex: 0,
      previousDestination: null,
      previousSelectedCustomer: null,
      modifiedAttachments: null,
      states: [],
      availableStates: [],
      modifiedComplaintEditorState: {},
      detailsError: {},
      paramFields: [],
    };
  },
  mounted() {
    window.onresize = () => {
      this.onWidthChange();
    };

    this.fetchStates();
  },
  updated() {
    window.onresize = () => {
      this.onWidthChange();
    };
  },
  computed: {
    ...mapGetters("settings", ["getUserRestrictions"]),
    isSplitViewAvailable() {
      return this.windowWidth > 1000;
    },
    isEditActionButtonsVisible() {
      return (
        this.isEditMode &&
        ((this.viewMode === "single" && this.selectedViewTab === 0) ||
          this.viewMode === "split")
      );
    },
    isEditButtonVisible() {
      return (
        !this.isEditMode &&
        ((this.viewMode === "single" && this.selectedViewTab === 0) ||
          this.viewMode === "split") &&
        this.complaint.status &&
        this.complaint.status.id !== CplStates.CANCELED &&
        (this.complaint.externalModulesStates || {})
          .jiraModuleIntegrationActive === false
      );
    },
    isSubordinateButtonsVisible() {
      return (
        this.viewMode === "split" ||
        (this.viewMode === "single" && this.selectedViewTab === 1)
      );
    },
    userHasPermissionToModifyComplaint() {
      const subcategoryId = this.complaint.subCategory.id;
      return (
        subcategoryId &&
        this.getUserRestrictions.subCategory[subcategoryId].some(
          (restriction) => restriction.id === 102
        )
      );
    },
    isAnythingEdited() {
      return !isEmpty(this.modifiedComplaintEditorState);
    },
  },
  watch: {
    isSplitViewAvailable(isAvailable) {
      if (!isAvailable && this.viewMode === "split") {
        this.viewMode = "single";
      }
    },
    complaintId: {
      handler: function (newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.complaint = null;
          this.fetchComplaint(newValue);
          this.restoreDefaultSettings();
        }
      },
      immediate: true,
    },
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.onComplaintChange(to.params.id);
      }
    },
    "complaint.isParent"(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.selectedViewTab = 0;
      }
    },
    "complaintEditorState.status"(newValue, oldValue) {
      if (newValue && newValue.id && newValue.id !== oldValue.id) {
        DictionaryService.GetParamsForComplaints(
          this.complaint.subCategory.id,
          newValue.id
        ).then((response) => {
          this.paramFields = response;
        });
      }
    },
  },
  methods: {
    checkIsValidComplaintDetails() {
      this.detailsError = {};
      let errorsDetails = {};

      const detailsParams = this.complaintEditorState.details[0].params;

      this.paramFields.forEach((paramField) => {
        if (paramField.required) {
          const detailsField = detailsParams.find(
            (x) => x.paramId === paramField.id
          );

          if (
            !detailsField ||
            (!detailsField.paramValue && !detailsField.dictParamValueId)
          ) {
            errorsDetails = {
              ...errorsDetails,
              [paramField.id]: this.$t("rules.required"),
            };
          }
        }
      });

      this.detailsError = errorsDetails;
      return Object.keys(this.detailsError).length === 0;
    },
    onComplaintChange(complaintId) {
      this.isEditMode = false;
      this.complaintId = complaintId;
    },
    setComplaintId(complaintId) {
      this.complaintId = complaintId;
    },
    setPreviousDestinaion(previousDestination) {
      this.previousDestination = previousDestination;
    },
    setPreviousCustomer(customer) {
      this.previousSelectedCustomer = customer;
    },
    onWidthChange() {
      const container = document.getElementsByClassName("parent-complaint");
      this.windowWidth =
        container && container[0] ? container[0].offsetWidth : undefined;
    },
    changeViewMode(viewMode) {
      this.viewMode = viewMode;
    },
    onSubordinateComplaintSelect(id) {
      this.selectedSubordinateComplaintIds.push(id);
    },
    onDeleteSubordinateComplaint() {
      if (this.selectedSubordinateComplaintIds.length > 0) {
        SimilarComplaintsService.DeleteComplaintFromSubordinates(
          this.complaintId,
          this.selectedSubordinateComplaintIds
        ).then(() => {
          this.updateParentComplaint();
          this.selectedSubordinateComplaintIds = [];
        });
      }
    },
    onAddSubordinateComplaint(complaintIdToAdd) {
      this.isLoading = true;

      SimilarComplaintsService.AddComplaintToSubordinates(this.complaintId, [
        complaintIdToAdd,
      ])
        .then(() => {
          this.updateParentComplaint();
          this.selectedSubordinateComplaintIds = [];
        })
        .finally(() => (this.isLoading = false));
    },
    fetchComplaint(complaintId) {
      this.isLoading = true;
      this.isEditMode = false;

      ComplaintsService.GetComplaint(complaintId)
        .then((response) => {
          const complaintData = {
            ...response,
            insertDate: this.$moment(response.insertDate),
            realisationDate: response.realisationDate
              ? this.$moment(response.realisationDate)
              : null,
          };

          this.complaint = cloneDeep(complaintData);
          this.complaintEditorState = cloneDeep(complaintData);
          this.baseStateId = cloneDeep(response.status.id);
          this.baseDetails = cloneDeep(response.details);
          this.availableStates = this.getAvailableStates();

          DictionaryService.GetParamsForComplaints(
            this.complaint.subCategory.id,
            this.complaint.status.id
          ).then((response) => {
            this.paramFields = response;
          });
        })
        .finally(() => (this.isLoading = false));
    },
    fetchSimilarComplaints() {
      this.isLoading = true;

      SimilarComplaintsService.GetSimilarComplaints(this.complaintId)
        .then((response) => {
          this.similarComplaints = response;
        })
        .finally(() => (this.isLoading = false));
    },
    fetchStates() {
      ComplaintsService.GetStatesForComplaintDetails().then((response) => {
        this.states = response;
      });
    },
    getAvailableStates() {
      const currentState = this.states.find(
        (state) => state.stateId === this.complaint.status.id
      );

      const availableNextSteps = (currentState || {}).nextStates
        ? [
            ...currentState.nextStates.split(",").map((x) => Number(x)),
            currentState.stateId,
          ]
        : [];

      return this.states
        .filter((x) => availableNextSteps.includes(x.stateId))
        .map((status) => ({
          id: status.stateId,
          text: status.name,
          ownerRequired: status.isOwnerRequired,
          code: status.code,
        }));
    },
    updateParentComplaint() {
      this.fetchComplaint(this.complaintId);
      this.componentKey += 1;
    },
    onTabChange(newSelectedTabIndex) {
      this.selectedTabIndex = newSelectedTabIndex;
    },
    onSelectViewTabChange(index) {
      this.selectedViewTab = index;
    },
    setEditMode() {
      this.isEditMode = true;
      if (Object.keys(this.complaint.details).length !== 0) {
        this.checkIsValidComplaintDetails();
      }
    },
    goBack() {
      const destinationName =
        this.previousDestination === "customerComplaints"
          ? "customerComplaints"
          : "complaints";

      this.$nextTick(() =>
        this.$router.push({
          name: destinationName,
          params: { initialSelectedCustomer: this.previousSelectedCustomer },
        })
      );
    },
    onSave() {
      this.saveAction();
    },
    onSaveAndClose() {
      this.saveAction(true);
    },
    async saveAction(closeAfterSave) {
      this.isLoading = true;
      const isValidate = validateComplaintBeforeSave(this.complaintEditorState);
      const complaintHasDetailsParams =
        Object.keys(this.complaint.details).length !== 0;

      if (!isValidate) {
        EmitError(this.$t("rules.validationErrorsBeforeSave"));
        this.isLoading = false;
        return;
      }

      if (complaintHasDetailsParams) {
        const detailsErrors = this.checkIsValidComplaintDetails();
        if (!detailsErrors) {
          EmitError(this.$t("rules.validationErrorsBeforeSave"));
          this.isLoading = false;
          return;
        }
      }

      ComplaintsService.UpdateComplaint(
        this.modifiedComplaintEditorState,
        this.complaintId
      )
        .then((response) => {
          EmitSuccess(
            this.$t("complaints.newCplUpdated", { cpl: response.cplTopic })
          );

          if (closeAfterSave || !response.cplId) {
            this.isEditMode = false;
            this.$nextTick(() => this.$router.push({ name: "complaints" }));
          } else {
            this.isEditMode = false;
            this.modifiedComplaintEditorState = {};

            if (this.complaint.isParent) {
              this.componentKey += 1;
            }

            this.fetchComplaint(response.cplId);
            this.fetchSimilarComplaints();
          }
        })
        .finally(() => (this.isLoading = false));
    },
    onCancel() {
      this.isEditMode = false;

      const attachments =
        this.modifiedAttachments || this.complaint.attachments;

      this.complaintEditorState = {
        ...cloneDeep(this.complaint),
        attachments: attachments,
      };
      this.modifiedComplaintEditorState = {};
    },
    onValueChange(newChanges) {
      this.complaintEditorState = {
        ...this.complaintEditorState,
        ...newChanges,
      };
      this.modifiedComplaintEditorState = {
        ...this.modifiedComplaintEditorState,
        ...newChanges,
      };
      this.checkIsValidComplaintDetails();
    },
    onStatusChange(status) {
      let details;
      this.complaintEditorState.status = status;

      if (status && status.id) {
        if (status.id !== this.baseStateId) {
          details = [{ stateId: status.id, params: [] }, ...this.baseDetails];
        } else {
          details = this.baseDetails;
        }
      }

      this.complaintEditorState.details = details;
      this.modifiedComplaintEditorState = {
        ...this.modifiedComplaintEditorState,
        status: status,
        details: details,
      };
    },
    onAttachmentsChange(newAttachments) {
      this.modifiedComplaintEditorState = {
        ...this.modifiedComplaintEditorState,
        attachments: newAttachments,
      };

      this.complaintEditorState.attachments = newAttachments;
      this.modifiedAttachments = newAttachments;
    },
    restoreDefaultSettings() {
      this.modifiedAttachments = null;
      this.selectedSubordinateComplaintIds = [];
      this.selectedTabIndex = 0;
      this.selectedViewTab = 0;
      this.viewMode = "single";
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scope lang="scss">
.container-loader {
  position: absolute;
  top: 0;
  left: 0;
}

.fa-times::before {
  font-size: 25px;
}

.stem-confirm:before {
  font-weight: bold;
  font-size: 20px;
}

.complaint,
.parent-complaint {
  margin: 20px -12px -12px -12px;

  .header-buttons {
    margin-left: auto;
    margin-right: 20px;
    align-self: center;
    display: flex;
    flex-wrap: nowrap;

    .long-button-icon {
      width: 55px;
      text-align: center;
    }

    .edit-action-buttons > button {
      margin-right: 10px;
      height: 32px;
      width: 64px;
      border-radius: 5px;
    }
  }

  .v-slide-group__content {
    padding-top: 2px; // to display box shadow on top
    // border-bottom: 1px solid #e0e0e0;

    .header-tab-item {
      min-width: 150px;
      border-radius: 0 20px 0 0;
      background-color: $tab-item-background;
      font-weight: bold;
      margin-right: 2px; // to display box shadow on right
      margin-bottom: -2px; // to hide box shadow on tab's bottom
      box-shadow: 1px -1px 5px -3px rgba(66, 68, 90, 1);

      &.v-tab--active {
        background-color: white;
      }

      .icon-wrapper {
        margin-left: 5px;
      }

      .go-back-arrow {
        margin-left: -20px;
        margin-right: 10px;
      }

      .header-tab-item-selected {
        color: $text-title;
      }
    }

    .header-tab-item::before {
      border-radius: 0 20px 0 0;
    }
  }
}

.parent-complaint {
  max-height: calc(100vh - 110px);
  overflow: hidden;

  // .single {}

  .split {
    display: flex;
    flex-direction: row;

    .left-side {
      width: 50%;
      overflow: auto;
      background-color: #ffffff;
    }

    .right-side {
      width: 50%;
      margin-left: 3px;
      overflow: auto;
      box-shadow: -3px 0px 3px -3px #42445a;
      background-color: #ffffff;
    }
  }
}
</style>

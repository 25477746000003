<template>
  <div class="dynamic-complaint-details-container">
    <div
      v-for="(detail, index) in details"
      :key="index"
      class="complaint-details-dynamic-card"
      :style="{ borderBottom: `solid ${getStateColor(detail.stateId)}  3px` }"
    >
      <div class="card-header">
        <span class="name">{{ getPropOfState(detail.stateId, "name") }}</span>
        <div class="move-right">
          <span class="date">
            {{ detail.insertDate | moment("displayDateTime") }}
          </span>
          <div class="expand-button">
            <IconButton
              v-if="
                isCardExpanded(index) || isExpandForEdit(index, detail.stateId)
              "
              @click="onExpandButtonClick(index)"
              :disabled="isExpandForEdit(index, detail.stateId)"
            >
              fa-caret-up
            </IconButton>
            <IconButton @click="onExpandButtonClick(index)" v-else>
              fa-caret-down
            </IconButton>
          </div>
        </div>
      </div>

      <div
        class="card-content-edit"
        v-if="isExpandForEdit(index, detail.stateId)"
      >
        <ComplaintDynamic
          ref="params"
          :subCategoryId="subCategoryId"
          :state="stateId"
          :defaults="detail.params"
          :value="details[index].params"
          :detailsError="detailsError"
          @input="(newValue) => onParamsChange(index, newValue)"
        />
      </div>

      <div
        class="card-content-display"
        v-if="!isExpandForEdit(index, detail.stateId) && isCardExpanded(index)"
      >
        <CategoryField
          v-for="(param, index) in detail.params"
          :key="index"
          :label="param.paramName"
        >
          <span v-if="param.paramTypeCode === 'P_DATETIME' && param.paramValue">
            {{ $moment(param.paramValue).format("displayDateTime") }}
          </span>
          <span
            v-else-if="
              param.paramTypeCode === 'P_DATETIME_FROM_TO' && param.paramValue
            "
          >
            {{ $t("common.from") }}
            {{
              $moment(JSON.parse(param.paramValue).From).format(
                "displayDateTime"
              )
            }}
            {{ $t("common.to") }}
            {{
              $moment(JSON.parse(param.paramValue).To).format("displayDateTime")
            }}
          </span>
          <span
            v-else-if="param.paramTypeCode === 'P_BOOL' && param.paramValue"
          >
            {{ param.paramValue ? $t("common.yes") : $t("common.no") }}
          </span>
          <span v-else>{{ param.paramValue }}</span>
        </CategoryField>
        <div class="no-params-info" v-if="detail.params.length === 0">
          {{ $t("complaints.noParams") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getComplaintStateColor } from "@/utils/ComplaintStatesHelper";

import IconButton from "@/components/Shared/IconButton";
import ComplaintDynamic from "../Complaints/ComplaintDynamic";
import CategoryField from "../Fields/CategoryField";

export default {
  props: {
    edit: { type: Boolean, default: false },
    subCategoryId: { type: Number, required: true },
    baseStateId: { type: Number, required: true },
    stateId: { type: Number, required: true },
    states: { type: Array, required: true },
    value: { type: Array, required: true },
    detailsError: Object,
  },
  components: {
    IconButton,
    CategoryField,
    ComplaintDynamic,
  },
  data() {
    return {
      details: [...this.value],
      expandedItemsIndex: [],
    };
  },
  methods: {
    onParamsChange(index, newValue) {
      const modifiedDetails = this.details;
      modifiedDetails[index].params = newValue;
      this.$emit("input", modifiedDetails);
    },
    // isValid() {
    //   if (this.detailsError.langth) return;
    //   return this.$refs["params"] && this.$refs["params"][0]
    //     ? this.$refs["params"][0].checkIsValid()
    //     : true;
    // },
    getPropOfState(stateId, prop) {
      return (this.states.find((s) => s.stateId === stateId) || {})[prop];
    },
    getStateColor(stateId) {
      return getComplaintStateColor(stateId);
    },
    onExpandButtonClick(itemIndex) {
      if (this.expandedItemsIndex.includes(itemIndex)) {
        this.expandedItemsIndex = this.expandedItemsIndex.filter(
          (expandedItem) => expandedItem !== itemIndex
        );
      } else {
        this.expandedItemsIndex.push(itemIndex);
      }
    },
    isCardExpanded(itemIndex) {
      return this.expandedItemsIndex.includes(itemIndex);
    },
    isExpandForEdit(itemIndex, itemStateId) {
      return this.edit && this.stateId === itemStateId && itemIndex === 0;
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value !== this.details) {
          this.details = [...value];
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("settings", ["isMobile"]),
  },
};
</script>

<style scoped lang="scss">
.dynamic-complaint-details-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.complaint-details-dynamic-card {
  margin-top: 20px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.23);
  padding: 20px;
  min-height: 85px; // equal to content-section's height

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $dynamic-complaint-details-font-color;

    .move-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .date {
        margin-right: 10px;
      }
    }
  }

  .card-content-display {
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    & > div {
      margin-top: 20px;
      margin-right: 5px;
      word-break: break-word;
    }

    .no-params-info {
      width: 100%;
    }
  }
}
</style>

import axios from "../plugins/http";

let urlApi = "api/dictionary";

export default {
  GetDictionary(dictId, selectedValueId = null) {
    if (!selectedValueId) {
      return axios.get(`${urlApi}/${dictId}`);
    } else {
      return axios.get(`${urlApi}/${dictId}/${selectedValueId}`);
    }
  },
  GetSubDictionary(dictId, parentValue, selectedValueId = null) {
    if (!selectedValueId) {
      return axios.get(`${urlApi}/${dictId}/parent-value/${parentValue}`);
    } else {
      return axios.get(
        `${urlApi}/${dictId}/parent-value/${parentValue}/${selectedValueId}`
      );
    }
  },
  GetAllCategories() {
    return axios.get(`${urlApi}/categories`);
  },
  GetCategories(restrictionId) {
    return axios.get(`${urlApi}/categories/${restrictionId}`);
  },
  GetSubcategories(categoryId) {
    return axios.get(`${urlApi}/subcategories/${categoryId}`);
  },
  GetParamsForComplaints(subcategoryId, cplStateId) {
    return axios
      .get(`${urlApi}/subcategories/params`, {
        params: { subcategoryId, cplStateId },
      })
      .then((r) => {
        return r.sort((a, b) => a.order - b.order);
      });
  },
  GetAllParams(subcategoryId) {
    const data = { params: { subcategoryId } };
    return axios
      .get(`${urlApi}/subcategories/paramsAll`, data)
      .then((response) => {
        return response.sort((a, b) => a.order - b.order);
      });
  },
  GetAllTags() {
    return axios.get(`${urlApi}/tags`);
  },
  GetSubcategoryTags(subcategoryId) {
    return axios.get(`${urlApi}/subcategories/${subcategoryId}/tags`);
  },
  AddTagToSubcategory(subcategoryId, tagValueText) {
    const url = `${urlApi}/subcategories/${subcategoryId}/tag`;

    return axios.post(url, { name: tagValueText });
  },
  RemoveTagFromSubcategory(subcategoryId, tagId) {
    return axios.delete(`${urlApi}/subcategories/${subcategoryId}/${tagId}`);
  },
  async GetDictionaryTagsSearch(searchValue, pageNumber = 1, pageSize = 200) {
    const response = await axios.get(
      `${urlApi}/tags/search?search=${searchValue}&pagenumber=${pageNumber}&pagesize=${pageSize}`
    );
    return response.data;
  },
  GetCategoriesForNewComplaint() {
    return axios.get(`${urlApi}/newcomplaints/categories/102`);
  },
};

import {
  P_STRING,
  P_BOOL,
  P_DATETIME,
  P_DATETIME_FROM_TO,
  P_DECIMAL,
  P_DICT,
  P_REST,
} from "./FieldTypes";

function importView(view) {
  return () =>
    import(
      /* webpackChunkName: "field-[request]" */ `../components/Fields/${view}.vue`
    );
}

export const fieldComponents = {
  [P_STRING]: importView("InputField"),
  [P_DECIMAL]: importView("InputField"),
  [P_BOOL]: importView("BooleanField"),
  [P_DATETIME]: importView("DatetimePicker"),
  [P_DATETIME_FROM_TO]: importView("DatetimeFromToPicker"),
  [P_DICT]: importView("SelectField"),
  [P_REST]: importView("SelectField"),
};

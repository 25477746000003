<template>
  <div>
    <div v-if="isLoading">
      <v-progress-linear
        class="comments-loader"
        indeterminate
        v-show="isLoading"
        color="loaderColor"
      />
    </div>
    <div class="comments-list" v-if="items.length > 0">
      <div class="comment" v-for="(item, index) in items" :key="index">
        <div class="header">
          <div class="owner">{{ item.owner.text }}</div>
          <div class="date">
            {{ item.insertDate | moment("displayDateTime") }}
          </div>
        </div>
        <div class="content">{{ item.message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ComplaintsService from "@/services/ComplaintsService";

export default {
  name: "CommentsList",
  created() {
    this.getComments();
  },
  props: {
    complaintId: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      items: [],
    };
  },
  computed: {
    isOutdated() {
      return this.$store.state.complaintDetails.isCommentsOutdated;
    },
  },
  methods: {
    ...mapActions({
      setIsComplaintCommentsOutdated: "setIsComplaintCommentsOutdated",
    }),
    getComments() {
      this.isLoading = true;
      return ComplaintsService.getComplaintComments(this.complaintId)
        .then((response) => {
          this.items = response;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    isOutdated(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.getComments().then(() => {
          this.$emit("scrollDown");
          this.setIsComplaintCommentsOutdated(false);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.comments-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .header {
      display: flex;
      flex-direction: row;
      font-size: 12px;

      .date {
        margin-left: auto;
      }
    }
  }
}
</style>

<template>
  <v-dialog v-if="complaint" v-model="showModal" persistent width="700">
    <v-card class="content-card">
      <div class="content-header">
        <IconBox color="iconButton" :styles="{ 'font-size': '20px' }">
          stem-realizationReminder
        </IconBox>
        <div class="header-title">
          {{ $t("complaints.realizationReminder").toUpperCase() }}
        </div>
      </div>

      <div v-if="complaint" class="content-details">
        <div class="complaint-details">
          <div class="field">
            <div class="label">{{ $t("complaints.complaintNumber") }}</div>
            <div class="value">{{ complaint.cId }}</div>
          </div>

          <div class="field">
            <div class="label">{{ $t("complaints.owner") }}</div>
            <div class="value">{{ complaint.owner.text }}</div>
          </div>
        </div>

        <div class="content-message">
          <div class="label">{{ $t("common.content") }}</div>
          <BaseTextarea v-model.trim="message" rows="5" solo />
        </div>
      </div>

      <div class="content-footer">
        <v-btn
          color="roundButton roundButtonText--text"
          rounded
          @click="onCancel"
        >
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          color="roundButton roundButtonText--text"
          rounded
          :disabled="!message"
          :loading="isLoading"
          @click="onConfirm"
        >
          {{ $t("common.send") }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ComplaintsService from "@/services/ComplaintsService";

export default {
  name: "SendRealizationReminderModal",
  data() {
    return {
      showModal: false,
      complaint: null,
      message: null,
      isLoading: false,
    };
  },
  methods: {
    openModal(complaint) {
      this.showModal = true;
      this.complaint = complaint;
      this.message = null;
      this.isLoading = false;
    },
    onCancel() {
      this.showModal = false;
    },
    onConfirm() {
      this.showModal = false;

      ComplaintsService.SendReminderMessage(this.complaint.id, this.message);
    },
  },
};
</script>

<style scoped lang="scss">
.content-card {
  height: 500px;
  padding: 40px;
  display: flex;
  flex-direction: column;

  .content-header {
    flex: 0 0 50px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-title {
      color: $text-title;
      margin-left: 10px;
    }
  }

  .content-details {
    flex: 1 0 auto;
    padding-top: 30px;

    .content-message {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      margin-top: 20px;

      .label {
        color: $text-color;
      }
    }

    .complaint-details {
      display: flex;

      .field {
        flex: 0 0 50%;
        padding: 0 10px;
        display: flex;
        flex-direction: column;

        .label {
          color: $text-color;
        }

        .value {
          padding: 10px;
          color: gray;
          background-color: $content-section-background;
        }
      }
    }
  }

  .content-footer {
    flex: 0 0 50px;
    margin-left: auto;

    :not(:first-child) {
      margin-left: 10px;
    }
  }
}
</style>

<template>
  <v-container grid-list-lg pa-1>
    <div v-if="isLoading" />
    <v-layout v-else wrap>
      <v-flex
        v-for="item in properties"
        :key="item.id"
        xs12
        v-bind="width(item)"
        pt-0
      >
        <component
          ref="dynamicFields"
          :is="item.typeCode"
          :errorMessage="getErrorMessage(item.id)"
          v-bind="item"
          @onChange="valueChange"
        ></component>
      </v-flex>
      <v-flex v-if="properties.length === 0">
        {{ $t("complaints.noParams") }}
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { P_STRING, P_DATETIME, P_DICT } from "@/utils/FieldTypes";
import DictionaryService from "@/services/DictionaryService";
import { fieldComponents } from "@/utils/FormGenerator";

export default {
  name: "ComplaintDynamic",
  props: {
    value: Array,
    subCategoryId: Number,
    state: { type: Number, default: 1 },
    defaults: Array,
    clone: { type: Boolean, default: false },
    detailsError: Object,
  },
  components: fieldComponents,
  data() {
    return {
      properties: [],
      hint: false,
      cascade: [],
      model: [],
      isLoading: false,
    };
  },
  mounted() {
    if (this.subCategoryId !== undefined) this.getParams();
  },
  watch: {
    subCategoryId(val) {
      if (val) this.getParams();
    },
    state() {
      this.getParams();
    },
    properties() {
      this.model = [];
      this.properties.forEach((i) => {
        this.model[i.id] = {
          id: i.detailId,
          paramId: i.id,
          paramName: i.name,
          paramTypeCode: i.typeCode,
          paramValue: i.paramValue,
          dictParamId: i.dictId,
          dictParamValueId: i.dictParamValueId,
          required: i.required,
        };
      });
      this.$emit("loaded");
    },
    model(newVal) {
      if (this.clone) {
        newVal
          .filter((x) => x)
          .forEach((el, index) => {
            this.properties[index] = {
              dictId: el.dictParamId,
              dictParamValueId: el.dictParamValueId,
              id: el.paramId,
              name: el.paramName,
              mode:
                el.dictParamValueId != null &&
                el.dictParamValueId.toString().includes(",")
                  ? 1
                  : 0,
              order: this.properties[index].order,
              paramValue: el.paramValue,
              required: el.required,
              typeCode: el.paramTypeCode,
            };
          });
      }
    },
  },
  methods: {
    getErrorMessage(paramId) {
      if (!this.detailsError || Object.keys(this.detailsError).length == 0) {
        return null;
      }
      return this.detailsError[paramId];
    },
    getParams() {
      this.isLoading = true;

      DictionaryService.GetParamsForComplaints(this.subCategoryId, this.state)
        .then((response) => {
          this._setProperties(response);
        })
        .finally(() => (this.isLoading = false));
    },
    checkIsValid() {
      var validationStatus = true;

      if (!this.$refs["dynamicFields"]) return true;

      this.$refs["dynamicFields"].forEach((el) => {
        let elementValidation = el.checkIsFieldValid();
        validationStatus = elementValidation && validationStatus;
      });
      return validationStatus;
    },
    getModel() {
      return this.model.filter((item) => item);
    },
    valueChange(val) {
      this._setCascadeDictionary(val);
      this._setModel(val);

      let newProperties = [];
      this.properties.forEach((item) => {
        if (item.id === val.id) {
          if (item.typeCode === P_DICT && item.dictId) {
            newProperties.push({
              ...item,
              dictParamValueId: val.value ? val.value.id : null,
              paramValue: val.value ? val.value.text : null,
            });
          } else {
            newProperties.push({ ...item, paramValue: val.value });
          }
        } else {
          newProperties.push(item);
        }
      });
      this.properties = newProperties;
      this.$emit("input", this.getModel());
    },
    width({ typeCode, mode }) {
      return {
        sm12: typeCode !== P_DATETIME && !(typeCode === P_STRING && mode === 1),
      };
    },
    _getDefaultValue(id, propName) {
      if (this.defaults) {
        const elem = this.defaults.find((v) => v.paramId === id);
        if (elem !== undefined) return elem[propName];
      }
      return undefined;
    },
    _setProperties(res) {
      this.properties = res.map((i) => {
        if (i.parentId) {
          const parentProperty = this._findPropertyParent(res, i.parentId);
          i.parentValue = parentProperty.dictParamValueId || -1;
          this._setCascade(res, i.id, i.parentId);
        }
        i.dictParamValueId = this._getDefaultValue(i.id, "dictParamValueId");
        i.paramValue = this._getDefaultValue(i.id, "paramValue");
        i.detailId = this._getDefaultValue(i.id, "id");
        i.attribute = i.attribute ? JSON.parse(i.attribute) : null;

        return i;
      });
    },
    _setCascade(response, propertyId, parentId) {
      let parentProperty = this._findPropertyParent(response, parentId);
      this.cascade[parentProperty.id] = propertyId;
    },
    _findProperty(id) {
      return this.properties.find((f) => f.id === id) || {};
    },
    _findPropertyParent(response, parentId) {
      return response.find((f) => f.id === parentId);
    },
    _setCascadeDictionary(e) {
      let subPropertyId = this.cascade[e.id];
      if (subPropertyId) {
        let property = this._findProperty(subPropertyId);
        property.parentValue = e.value ? e.value.id : undefined;
        this.model[property.id].paramValue = [];
      }
    },
    _setModel(e) {
      let propModel = this.model[e.id];
      if (this.$moment.isMoment(e.value)) {
        propModel.paramValue = e.value.toJSON();
      } else if (typeof e.value === "object") {
        if (e.value instanceof Array) {
          propModel.dictParamValueId = e.value
            ? e.value.map((i) => i.id).join(",")
            : null;
          propModel.paramValue = e.value
            ? e.value.map((i) => i.text).join(",")
            : null;
        } else {
          propModel.dictParamValueId = e.value ? e.value.id : null;
          propModel.paramValue = e.value ? e.value.text : null;
        }
      } else if (typeof e.value === "boolean") {
        propModel.paramValue = e.value.toString();
      } else {
        propModel.paramValue = e.value;
      }
    },
  },
};
</script>

<template>
  <div class="new-comment-text-field">
    <CategoryField
      required
      textAreaField
      rows="5"
      :edit="true"
      :label="$t('common.addComment')"
      v-model.trim="message"
      :error-messages="errors.message"
    >
      {{ message }}
    </CategoryField>
    <div class="send-button">
      <v-btn icon text normal @click="sendMessage">
        <IconBox :tooltip="$t('common.addComment')" color="iconButton">
          stem-send
        </IconBox>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import validationThings from "@/mixins/validation-things";

import CategoryField from "@/components/Fields/CategoryField";

export default {
  name: "NewCommentTextField",
  components: { CategoryField },
  mixins: [validationThings],
  validations: {
    message: {
      required,
    },
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    async sendMessage() {
      if (!this.message || !(await this.validateModelAsync())) return;

      this.$emit("sendMessage", this.message);
      this.message = "";
      this.$v.$reset();
    },
  },
};
</script>

<style scoped lang="scss">
.new-comment-text-field {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .send-button {
    display: flex;
    margin-left: auto;
  }
}
</style>

<template>
  <div
    class="complaint-details-card"
    :class="{ errorBorder: error }"
    :style="{
      borderLeft: `solid ${getStateColor(complaint.stateId)} 10px`,
    }"
  >
    <v-layout
      overflow-hidden
      justify-space-between
      align-center
      class="inline-content-details textColor--text"
      @click="onClick"
    >
      <a
        class="complaint-link"
        :href="`/complaints/${complaint.cplId}`"
        target="_blank"
        @click="onComplaintIdClick"
      >
        <div class="priority">
          <PriorityArrow
            :id="complaint.priorityId"
            :height="15"
            :name="priorityName"
          />
        </div>
        <v-flex xs2>
          <v-flex class="header-title">
            <TooltipTruncate :attrs="'heading bold title-text'">
              {{ complaint.topic }}
            </TooltipTruncate>
          </v-flex>
        </v-flex>
        <v-flex xs2>
          <TooltipTruncate :attrs="'subheading bold category-section'">
            <span class="value">{{ complaint.categoryName }}</span>
          </TooltipTruncate>
        </v-flex>
        <v-flex xs1>
          <a
            class="complaint-id"
            :href="`/complaints/${complaint.cplId}`"
            target="_blank"
            @click="onComplaintIdClick"
          >
            {{ complaint.cid }}
          </a>
        </v-flex>
        <v-flex xs2 class="label-value">
          <span class="label">{{ $t("common.created") }}</span>
          <div>{{ complaint.created | moment("displayDate") }}</div>
        </v-flex>
        <v-flex xs2 class="label-value">
          <span class="label">{{ $t("complaints.owner") }}</span>
          <TooltipTruncate>{{ complaint.complainerUser }}</TooltipTruncate>
        </v-flex>

        <div class="inline-content-buttons">
          <div v-if="cardType === 'subordinate'" class="card-select">
            <div class="select-field">
              <v-checkbox
                color="fields"
                :input-value="isSelected"
                @click.stop.prevent="$emit('select')"
              />
            </div>
          </div>
        </div>
      </a>
    </v-layout>
    <div class="card-description">
      <span v-if="complaint.similarity" class="similarity">
        {{ `${complaint.similarity}% ` }}
      </span>
      {{ complaint.description }}
    </div>

    <div class="card-footer">
      <div v-if="cardType === 'similar'" class="similar-card-footer">
        <div
          v-if="complaint.isChildOfMainCpl || complaint.parentCid"
          class="subordinate-complaint-icon"
        >
          <IconBox color="iconButton">stem-subordinateComplaint</IconBox>
        </div>

        <div v-if="complaint.isParent" class="parent-complaint-icon">
          <IconBox color="iconButton">stem-parentComplaint</IconBox>
        </div>

        <v-btn
          v-if="showAddButton"
          class="subordinate-button add"
          color="roundButton roundButtonText--text"
          rounded
          @click="onAddSubordinateButtonClick()"
        >
          {{ $t("complaints.setAsSubordinate") }}
        </v-btn>

        <v-btn
          v-if="showRemoveButton"
          class="subordinate-button remove"
          color="roundButton roundButtonText--text"
          rounded
          @click="onRemoveSubordinateButtonClick()"
        >
          {{ displaySubordinateRemoveButtonName() }}
        </v-btn>
      </div>
    </div>
    <ModalWindow ref="confirmModal" />
  </div>
</template>

<script>
import { getComplaintStateColor } from "@/utils/ComplaintStatesHelper";

import ModalWindow from "@/components/Shared/ModalWindow";
import PriorityArrow from "@/components/Shared/PriorityArrow";

export default {
  name: "ComplaintDetailsCard",
  components: {
    ModalWindow,
    PriorityArrow,
  },
  props: {
    complaint: Object,
    isSelected: Boolean,
    cardType: String,
    canAdd: Boolean,
    error: {
      type: String,
      default: "",
    },
    priorityName: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    showAddButton() {
      return (
        this.canAdd &&
        !this.complaint.isParent &&
        !this.complaint.parentCid &&
        !this.complaint.isChildOfMainCpl
      );
    },
    showRemoveButton() {
      return this.complaint.parentCid || this.complaint.isChildOfMainCpl;
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
    getStateColor(stateId = 2) {
      return getComplaintStateColor(stateId);
    },
    onAddSubordinateButtonClick() {
      this.$emit("addToSubordinate", this.complaint.cplId);
    },
    onRemoveSubordinateButtonClick() {
      if (this.complaint.isChildOfMainCpl) {
        this.$emit("removeFromSubordinate", this.complaint.cplId);
      } else if (this.complaint.parentCid) {
        this.showConfirmModalWindow();
      }
    },
    displaySubordinateRemoveButtonName() {
      if (this.complaint.isChildOfMainCpl) {
        return this.$t("complaints.removeFromSubordinate");
      } else if (this.complaint.parentCid) {
        return this.$t("complaints.removeFromOtherSubordinate", {
          parentId: this.complaint.parentCid,
        });
      }
    },
    onComplaintIdClick(event) {
      event.preventDefault();
    },
    showConfirmModalWindow() {
      const options = {
        width: 480,
      };

      this.$refs.confirmModal
        .open(
          this.$t("global.confirm").toUpperCase(),
          this.$t("complaints.confirmRemoveFromOtherSubordinateMessage", {
            parentId: this.complaint.parentCid,
          }),
          options
        )
        .then((result) => {
          if (result) {
            this.$emit(
              "removeFromOtherSubordinate",
              this.complaint.cplId,
              this.complaint.parentCplId
            );
          }
        });
    },
    onGoToComplaintDetails(complaintId) {
      this.$nextTick(() =>
        this.$router.push({
          name: "complaintDetails",
          params: { id: complaintId },
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.complaint-details-card:hover {
  background: $card-hover;
}

.complaint-details-card {
  margin-top: 20px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.19);

  .complaint-link {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-around;
    color: black;
    text-decoration: none;
  }

  .complaint-id {
    color: $text-color;
    font-weight: bold;
    text-decoration: none;
  }
  .header-title {
    font-size: 18px;
  }

  .category-section {
    color: $category-color;
    font-size: 16px;
    flex: 1 0 30%;
    padding: 0 10px 0 5px;
  }

  .label-value {
    .label {
      color: $label;
      margin-right: 5px;
    }
  }

  .card-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;

    .field {
      flex: auto;
      min-width: 85px;
      padding-left: 10px;
    }
  }

  .card-description {
    padding-top: 10px;
    font-size: 15px;
    margin-left: 30px;

    .similarity {
      margin-right: 10px;
      color: #d1cdca; // ciemniejsze
      // color: #e0e0e0; // jasne
      // color: gray; // ciemne
    }
  }

  .card-footer {
    margin: 0 30px;
    .similar-card-footer {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      align-items: center;

      .subordinate-button {
        margin-left: auto;
        font-size: 12px;
        border: 1px solid $text-color;
        box-shadow: none;
        height: 30px;
      }
    }

    .card-select {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      height: 40px;

      .select-field {
        margin-left: auto;
        align-self: center;

        .v-input--selection-controls {
          margin: 0;
          padding: 0;
          height: 26px;
          width: 26px;
        }
      }
    }
  }
}
</style>
